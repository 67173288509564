import { IMeasurement, MeasurementKey, MeasurementStatus } from './assessment.types';
import { endsWith, startsWith } from 'lodash';

import { IDType } from '../core.types';

export enum BodyParts {
	Root = 'root',
	Front = 'front',
	Back = 'back',
	// API General
	Arm = 'arm',
	Shoulder = 'shoulder',
	Hip = 'hip',
	Leg = 'leg',
	//Ankle = 'ankle',
	//Toe = 'toe',
	Neck = 'neck',
	Wrist = 'wrist',
	Torso = 'torso',
	Foot = 'foot',
	Spine = 'spine',
	//LowerBack = 'lower-back',
	// Internal Sub-category
	SpineBack = 'spine-back',
	LeftTorso = 'left-torso',
	RightTorso = 'right-torso',
	LeftWrist = 'left-wrist',
	RightWrist = 'right-wrist',
	LeftNeck = 'left-neck',
	RightNeck = 'right-neck',
	LeftArm = 'left-arm',
	RightArm = 'right-arm',
	LeftFoot = 'left-foot',
	RightFoot = 'right-foot',
	LeftShoulder = 'left-shoulder',
	RightShoulder = 'right-shoulder',
	LeftHip = 'left-hip',
	RightHip = 'right-hip',
	LeftLeg = 'left-leg',
	RightLeg = 'right-leg',
	//LeftAnkle = 'left-ankle',
	//RightAnkle = 'right-ankle',
	//LeftToe = 'left-toe',
	//RightToe = 'right-toe',
	// AngleSuffix to sub-categories
	LeftFootBack = 'left-foot-back',
	LeftFootSide = 'left-foot-side',
	RightFootBack = 'right-foot-back',
	RightFootSide = 'right-foot-side',
	LeftShoulderBack = 'left-shoulder-back',
	LeftShoulderSide = 'left-shoulder-side',
	RightShoulderBack = 'right-shoulder-back',
	RightShoulderSide = 'right-shoulder-side',
	LeftArmBack = 'left-arm-back',
	LeftArmSide = 'left-arm-side',
	RightArmBack = 'right-arm-back',
	RightArmSide = 'right-arm-side',
	LeftHipBack = 'left-hip-back',
	LeftHipSide = 'left-hip-side',
	RightHipBack = 'right-hip-back',
	RightHipSide = 'right-hip-side',
	LeftLegBack = 'left-leg-back',
	RightLegBack = 'right-leg-back',
}

export const bodyPartMeasurementMap = new Map<BodyParts, MeasurementKey[]>([
	[BodyParts.Neck, [MeasurementKey.NeckRotation]],
	[BodyParts.Shoulder, [MeasurementKey.ShoulderXtr90Abduction, MeasurementKey.ShoulderXtr180Flexion, MeasurementKey.ShoulderFlexion]],
	[BodyParts.Arm, [MeasurementKey.WristExtension, MeasurementKey.WristSupination, MeasurementKey.WristPronation]],
	[BodyParts.Spine, [MeasurementKey.TLJunction]],
	[BodyParts.Hip, [MeasurementKey.QuadratusFemoris, MeasurementKey.ObturatorInternus, MeasurementKey.Piriformis, MeasurementKey.FemoralExternalRotation]],
	[BodyParts.Leg, [MeasurementKey.HipExtension, MeasurementKey.TibialInternal, MeasurementKey.TibialExternal, MeasurementKey.HipFlexion]],
	[BodyParts.Foot, [MeasurementKey.AnkleDorsiflexion, MeasurementKey.GreatToeExtension]]
]);

export enum Side {
	left = 'left',
	right = 'right',
	center = 'center',
	bilateral = 'bilateral',
	all = 'all'
}
export type LimitedSide = Side.left | Side.right | Side.center;

export enum SidePrefix {
	left = 'left-',
	right = 'right-'
}

export enum AngleSuffix {
	front = '',
	back = '-back',
	side = '-side'
}

export const BODY_ANIMATION_TIME = 1000;

export const BodyPartsIDMap = new Map<BodyParts, IDType>();

export interface BodyPartStandard extends Pick<IMeasurement, 'id' | 'name' | 'bilateral' | 'leftStandard' | 'rightStandard' | 'centerStandard'> {
	key:BodyParts;
}
export interface IBodyPartGroup extends BodyPartStandard {
	innerBodyParts:BodyParts[];
}

export const getBodyPartGroup = (part:BodyParts | string, groups:IBodyPartGroup[]):IBodyPartGroup | undefined => {
	return groups.find(({key}) => key===part);
}

const createBodyPartsHierarchyMap = (groups:IBodyPartGroup[]):Map<BodyParts, BodyParts[]> => {
	type BodyPartsMap = [BodyParts, BodyParts[]];
	const bodyPartsHierarchy = groups.reduce((accu, {key, innerBodyParts}) => {
		accu.push([key, innerBodyParts]);
		return accu;
	}, [] as BodyPartsMap[]);
	bodyPartsHierarchy.push([BodyParts.Root, [BodyParts.Front, BodyParts.Back]]);
	return new Map(bodyPartsHierarchy);
}

const _getBodyPartsFromGroup = (parent:BodyParts, groupMap:Map<BodyParts, BodyParts[]>, goDeep:boolean=true):BodyParts[] => {
	let found = groupMap.get(parent);
	if(found && goDeep){
		found.forEach(part => {
			const grandkids = _getBodyPartsFromGroup(part, groupMap, true);
			if( grandkids.length ){
				found = found?.concat(grandkids);
			}
		})
	}
	return found || [];
}

export const getBodyPartsFromGroup = (parent:BodyParts, groups:IBodyPartGroup[], goDeep:boolean=true):BodyParts[] => {
	const groupsMap = createBodyPartsHierarchyMap(groups);
	return _getBodyPartsFromGroup(parent, groupsMap, goDeep);
}

export const getBodyPartsSiblings = (part:BodyParts, groups:IBodyPartGroup[]):BodyParts[] => {
	const groupsMap = createBodyPartsHierarchyMap(groups);
	let parent:BodyParts | undefined = BodyParts.Root;
	let queue:BodyParts[] = parent ? [parent] : [];
	while (queue.length > 0) {
		parent = queue.shift();
		if( parent ){
			const kids = _getBodyPartsFromGroup(parent, groupsMap, false);
			if(kids.includes(part)){
				return kids;
			} else {
				queue = queue.concat(kids);
			}
		}
	}
	return [];
}

export const getBodyPartLabel = (part: BodyParts):string => part.replaceAll('-', '_')

export const convertRemoteBodyPart = (input?:BodyParts | null):BodyParts | null | undefined => {
	return (input && typeof input==='string') ? (input.toLowerCase().replace('_', '-') as BodyParts) : input;
}

export const isZoomedIn = (part:BodyParts):boolean => {
	return (part!==BodyParts.Root) && (part!==BodyParts.Front) && (part!==BodyParts.Back);
}

// if a sub-part (right-shoulder-back or right-shoulder-side), will return the base (right-shoulder)
export const getBasePart = (part:BodyParts):BodyParts => {
	const noAngleBase = endsWith(part, AngleSuffix.back) ? part.substring(0, part.length-AngleSuffix.back.length) as BodyParts : 
		endsWith(part, AngleSuffix.side) ? part.substring(0, part.length-AngleSuffix.side.length) as BodyParts : part;
	return noAngleBase;
}

// if a sub-part (right-shoulder-back or right-shoulder-side), will return the root base (shoulder)
export const getBaseRootPart = (part:BodyParts):BodyParts => {
	const noAngleBase = getBasePart(part);
	const noSideBase = startsWith(noAngleBase, SidePrefix.left) ? noAngleBase.substring(SidePrefix.left.length) as BodyParts : 
		startsWith(noAngleBase, SidePrefix.right) ? noAngleBase.substring(SidePrefix.right.length) as BodyParts : noAngleBase;
	return noSideBase;
}

export const getBaseMeasurementKey = (key:MeasurementKey):MeasurementKey => {
	const noSideBase = startsWith(key, SidePrefix.left) ? key.substring(SidePrefix.left.length) as MeasurementKey : 
		startsWith(key, SidePrefix.right) ? key.substring(SidePrefix.right.length) as MeasurementKey : key;
	return noSideBase;
}

export const getWorstBodyPartStandard = (standard:BodyPartStandard, bilateral?:boolean) => {
	const tests = [MeasurementStatus.LEVEL_1, MeasurementStatus.LEVEL_2, MeasurementStatus.LEVEL_3, MeasurementStatus.LEVEL_4];
	for(let i=0;i<tests.length;i++){
		const test = tests[i];
		if(bilateral && (standard.leftStandard===test || standard.rightStandard===test)){
			return test;
		} else if(standard.leftStandard===test || standard.rightStandard===test || standard.centerStandard===test){
			return test;
		}
	}
	return undefined;
};

export const getBodyPartFromMeasurementKey = (key:MeasurementKey):BodyParts | undefined => {
	for (let [bodyPart, measurements] of Array.from(bodyPartMeasurementMap)) {
		if(measurements.includes(key)){
			return bodyPart;
		}
	}
	return undefined;
}

type MeasurementNameString = MeasurementKey | string;
const measurementUrlOverrides = new Map<MeasurementNameString, AngleSuffix[]>([
	/*[MeasurementKey.WristSupination, [AngleSuffix.side, AngleSuffix.back]],
	[MeasurementKey.WristExtension, [AngleSuffix.side, AngleSuffix.back]],
	[MeasurementKey.WristPronation, [AngleSuffix.front, AngleSuffix.back]],*/
]);

export const checkForMeasurementUrlOverride = (part:BodyParts, measurement:IMeasurement):BodyParts => {
	const overrideAngles = measurementUrlOverrides.get(measurement.name);
	if( overrideAngles ){
		const angle = endsWith(part, AngleSuffix.back) ? AngleSuffix.back : endsWith(part, AngleSuffix.side) ? AngleSuffix.side : AngleSuffix.front;
		if( !overrideAngles.includes(angle) && overrideAngles.length ){
			// override part
			part = (getBasePart(part) + overrideAngles[0]) as BodyParts;
		}
	}
	return part;
}

/* Some body parts show both left and right on same screen (ie. neck, spine)*/
export const doesPartShowBothSides = (part:BodyParts):boolean => {
	switch( part ){
		case BodyParts.Neck :
		case BodyParts.Spine :
			return true;
		default :
			return false;
	}
}