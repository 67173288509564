import { Box, Typography } from '@material-ui/core';
import { Colors, FontWeight } from '../../utils/style';
import { ConnectedProps, connect } from 'react-redux';
import {
    Divider,
    Hidden,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    SwipeableDrawer,
} from '@material-ui/core';
import { IOrg, OrgAccessLevel } from '../../redux/org/org.types';
import { IUser } from '../../redux/user/user.types';
import React, {Fragment, useCallback, useMemo} from "react";
import { Link as RouterLink, useHistory, useRouteMatch } from 'react-router-dom';
import {
	Theme,
	createStyles,
	makeStyles,
	useTheme
} from "@material-ui/core/styles";
import { getAccountPath, getAdminPath, getPlayerPath, getReportsPath, getSubscriptionPath } from '../../utils/routes';
import { selectAllOrgs, selectOrgInfo, selectTopOrgInfo } from '../../redux/org/org.selectors';

import AccessibilityIcon from '@material-ui/icons/Accessibility';
import CloseIcon from "@material-ui/icons/Close";
import HomeIcon from '@material-ui/icons/Home';
import { IRootState } from '../../redux/root.types';
import InfoIcon from '@material-ui/icons/Info';
import SubscriptionsIcon from '@material-ui//icons/Subscriptions';
import ResponsiveImage from '../responsive-image/responsonsive-image.component';
import Routes from '../../utils/routes';
import SelectNative from '../select-native/select-native.component';
import { TypographyProps } from '@material-ui/core/Typography';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import clsx from "clsx";
import { createStructuredSelector } from 'reselect';
import logo from '../../assets/pmotion-logo-horizontal.png';
import { selectCurrentUser, selectUserCanAccess, selectCurrentUserHasStaffAccess } from '../../redux/user/user.selectors';
import { setOrg } from '../../redux/org/org.actions';
import { trackOrgEvent } from '../../services/GoogleTagManager';
import ViewListIcon from '@material-ui/icons/ViewList';
import { useLocalization } from '../../localization/useLocalization';

const drawerWidth = 264;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		drawer: {
			
			[theme.breakpoints.up("sm")]: {
				width: drawerWidth,
				flexShrink: 0
			}
		},
		toolbar: {
			...theme.mixins.toolbar,
			display: "flex",
			backgroundColor: '#212223',
			color: Colors.White,
			alignItems: 'center'
		},
		drawerPaper: {
			width: drawerWidth
		},
		grow: {
			flexGrow: 1
		},
		flex: {
			display: "flex"
		},
		logoContainer: {
			textAlign: 'center',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'column',
			display: 'flex',
		},
		logoImage: {
			maxWidth: 106
		},
		closeBtn: {
			color: Colors.White
		},
		linkIcon: {
			minWidth: 0,
			marginRight: 10
		},
		linkText: {
			color: Colors.Black,

			'& .MuiTypography-body2': {
				fontSize: 14,

				[theme.breakpoints.down('sm')]: {
					fontSize: 13,
				},
			}
		},
		orgBox: {
			paddingTop: 17,
			paddingBottom: 17.5
		},
		orgHeader: {
			color: '#4d4d4d',
			display: 'block',
			marginBottom: 7,

			[theme.breakpoints.down('sm')]: {
				fontSize: 13,
			},
		},
		orgSelect: {

			'& .MuiSelect-select': {
				fontSize: theme.typography.pxToRem(14),
				lineHeight: theme.typography.pxToRem(19),
				height: theme.typography.pxToRem(19),
				fontWeight: FontWeight.Regular,
				padding: `4px 30px 5px 10px`,

				[theme.breakpoints.down('sm')]: {
					fontSize: 13,
					paddingTop: 6,
					paddingBottom: 7
				},
			},
			'& .MuiSelect-iconFilled': {
				right: theme.typography.pxToRem(2)
			}
		}
	})
);

interface mapStateToPropsInterface {
	user?:IUser;
	org?:IOrg;
	topOrg?:IOrg;
	allOrgs?:IOrg[];
	hasLevel2Access:boolean;
	hasLevel3Access:boolean;
	hasStaffAccess:boolean;
}
const mapStateToProps = createStructuredSelector<
	IRootState,
	mapStateToPropsInterface
>({
	user: selectCurrentUser,
	org: selectOrgInfo,
	topOrg: selectTopOrgInfo,
	allOrgs: selectAllOrgs,
	hasLevel2Access: selectUserCanAccess(OrgAccessLevel.Level2),
	hasLevel3Access: selectUserCanAccess(OrgAccessLevel.Level3),
	hasStaffAccess: selectCurrentUserHasStaffAccess
});

const mapDispatchToProps = {
    setOrg
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type NavDrawerProps = PropsFromRedux & {
	isOpen: boolean;
	onDrawerClose?: Function;
}

interface NavOption {
	Icon:any;
	link?:string;
}

const NavDrawer: React.FC<NavDrawerProps> = ({
	isOpen,
	onDrawerClose,
	user,
	org,
	topOrg,
	allOrgs,
	setOrg,
	hasLevel2Access,
	hasLevel3Access,
	hasStaffAccess
}) => {
	const classes = useStyles();
	const {t, hasLanguageLoaded} = useLocalization()
	const theme = useTheme();
	const history = useHistory();
	const homeMatch = useRouteMatch(Routes.HOME);

	const handleDrawerToggle = useCallback((bool:boolean) => {
		if (!bool && onDrawerClose) {
			onDrawerClose();
		}
	}, [onDrawerClose]);

	const onLinkClick = useCallback((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		handleDrawerToggle(false);
	}, [handleDrawerToggle]);
	
	type NavLinks = [string, NavOption][];
	/*const mainItemsMap:NavLink = [
		['Home', {
			Icon: HomeIcon,
			link: Routes.HOME
		}],
		['Training', {
			Icon: FitnessCenterIcon
		}],
		['Messages', {
			Icon: EmailIcon
		}]
	];
	const mainItems = Array.from(new Map<string, NavOption>([
		['Home', {
			Icon: HomeIcon,
			link: Routes.HOME
		}],
		['Training', {
			Icon: FitnessCenterIcon
		}],
		['Messages', {
			Icon: EmailIcon
		}]
	]));*/

	const mainItems:NavLinks = useMemo(() => {
		const items:NavLinks = [
			[t('common.drawer.home'), {
				Icon: HomeIcon,
				link: (org && org.memberId && !hasLevel2Access) ? getPlayerPath(org.memberId) : Routes.HOME
			}]
		];
		if( hasLevel2Access ){
			items.push([t('common.drawer.risk_overview'), {
				Icon: AccessibilityIcon,
				link: Routes.HOME_MANAGMENT
			}]);
		}
		return items;
	}, [hasLevel2Access, org, hasLanguageLoaded]);

	const subItems:NavLinks = useMemo(() => {
		const items:NavLinks = [];
		if( hasLevel3Access ){
			items.push([t('common.drawer.admin'), {
				Icon: VerifiedUserIcon,
				link: getAdminPath()
			}]);
			if( hasStaffAccess ){
				items.push(['Reports', {
					Icon: ViewListIcon,
					link: getReportsPath()
				}]);
			}
		}
		if ( user && user.subscription ) {
			items.push(['Subscription', {
				Icon: SubscriptionsIcon,
				link: getSubscriptionPath()
			}]);
		}
		items.push([t('common.drawer.help'), {
			Icon: InfoIcon,
			link: getAccountPath('help')
		}]);
		return items;
	}, [user, hasLevel3Access, hasStaffAccess, hasLanguageLoaded]);
	/*['Support', LiveHelpIcon]*/

	const onOrgSelect = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
		handleDrawerToggle(false);
		
		const selectedId = parseFloat(event.target.value as string);
		const selectedTopOrg = allOrgs ? allOrgs.find(({id}) => id===selectedId) : undefined;
		if( selectedTopOrg ){
			// select first child of top Org, if possible
			const selectedOrg = (selectedTopOrg.children && !!selectedTopOrg.children.length) ? selectedTopOrg.children[0] : selectedTopOrg;

			trackOrgEvent('Change Org', selectedTopOrg.key, topOrg?.key);

			setOrg( selectedOrg );

			if( !homeMatch || !homeMatch.isExact){
				history.push( Routes.HOME );
			}
		}
	}, [allOrgs, setOrg, handleDrawerToggle, history, homeMatch, topOrg]);

	type TypographyPropsOptionalType = Partial<TypographyProps>;
	const primaryTypographyProps:TypographyPropsOptionalType = {
		variant: "body2",
		variantMapping: {
			body2: 'span'
		}
	};

	const DrawerContent = useMemo(() => (
		<div>
			<div className={classes.toolbar}>
				<div className={clsx(classes.grow, classes.logoContainer)}>
					<ResponsiveImage className={classes.logoImage} src={logo} />
				</div>
				<div className={classes.flex}>
					<IconButton
						onClick={() => handleDrawerToggle(false)}
						aria-label="Close Nav"
						color="inherit" 
						className={clsx('close-btn', classes.closeBtn)}
					>
						<CloseIcon />
					</IconButton>
				</div>
			</div>
			{allOrgs && allOrgs.length > 1 && (
				<Fragment>
					<Divider />
					<Box className={clsx("MuiListItem-gutters", classes.orgBox)}>
						<Typography component="span" variant="body2" className={classes.orgHeader}>Organization</Typography>
						<SelectNative 
							className={classes.orgSelect}
							value={topOrg ? topOrg.id : undefined}
							onChange={onOrgSelect}
						>
							{allOrgs.map(({id, displayName}) => (
								<option key={id} value={id}>{displayName}</option>
							))}
						</SelectNative>
					</Box>
				</Fragment>
			)}
			<Divider />
			<List>
				{mainItems.map(
					([label, {Icon, link}], index) => (
						<Fragment key={label}>
							{link ? (
								<Link component={RouterLink} to={link} onClick={onLinkClick}>
									<ListItem>
										<ListItemIcon className={classes.linkIcon}>
											<Icon />
										</ListItemIcon>
										<ListItemText className={classes.linkText} primary={label} primaryTypographyProps={primaryTypographyProps} />
									</ListItem>
								</Link>
							) : (
								<ListItem>
									<ListItemIcon className={classes.linkIcon}>
										<Icon />
									</ListItemIcon>
									<ListItemText className={classes.linkText} primary={label} primaryTypographyProps={primaryTypographyProps} />
								</ListItem>
							)}
						</Fragment>
					)
				)}
			</List>
			{subItems.length > 0 && (
				<Fragment>
					<Divider />
					<List>
						{subItems.map(
							([label, {Icon, link}], index) => (
								<Fragment key={label}>
									{link ? (
										<Link component={RouterLink} to={link} onClick={onLinkClick}>
											<ListItem>
												<ListItemIcon className={classes.linkIcon}>
													<Icon />
												</ListItemIcon>
												<ListItemText className={classes.linkText} primary={label} primaryTypographyProps={primaryTypographyProps} />
											</ListItem>
										</Link>
									) : (
										<ListItem>
											<ListItemIcon className={classes.linkIcon}>
												<Icon />
											</ListItemIcon>
											<ListItemText className={classes.linkText} primary={label} primaryTypographyProps={primaryTypographyProps} />
										</ListItem>
									)}
								</Fragment>
							)
						)}
					</List>
				</Fragment>
			)}
		</div>
	), [classes, allOrgs, handleDrawerToggle, mainItems, onLinkClick, onOrgSelect, primaryTypographyProps, subItems, topOrg]);

	return (
		<nav className={classes.drawer} aria-label="nav links">
			{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
			<Hidden smUp implementation="css">
				<SwipeableDrawer
					variant="temporary"
					anchor={theme.direction === "rtl" ? "right" : "left"}
					open={isOpen}
					onOpen={() => handleDrawerToggle(true)}
					onClose={() => handleDrawerToggle(false)}
					classes={{
						paper: classes.drawerPaper
					}}
					ModalProps={{
						keepMounted: true // Better open performance on mobile.
					}}
				>
					{DrawerContent}
				</SwipeableDrawer>
			</Hidden>
		</nav>
	);
};

export default connector(NavDrawer);
