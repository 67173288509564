import { useState, useEffect } from 'react';
import { get } from 'lodash';

// Define the LanguageStrings interface in the hook
interface LanguageStrings {
  height_placeholder_inch?: string;
  height_placeholder_cm?: string;
  weight_placeholder_lb?: string;
  weight_placeholder_kg?: string;
  [key: string]: any;
}

export enum MeasurementUnit {
  Metric = 'METRIC',
  Imperial = 'IMPERIAL'
}

// Define the return value of the hook
interface UseLocalizationResult {
  country: string;
  measurementUnit: MeasurementUnit;
  hasLanguageLoaded: boolean;
  t: (token: string, variable?: string) => string;
  t_media: (token: string) => string;
}

export const useLocalization = (): UseLocalizationResult => {
  const [languageStrings, setLanguageStrings] = useState<LanguageStrings>({});

  const locale = navigator.language; // Get locale (e.g., 'en-US', 'it-IT')
  const [langCode, country] = locale.split('-'); // 'languageCode' and 'countryCode'
  const measurementUnit = country === 'US' ? MeasurementUnit.Imperial : MeasurementUnit.Metric
  const hasLanguageLoaded = !!Object.keys(languageStrings).length

  useEffect(() => {
    const loadLanguage = async (languageCode: string): Promise<LanguageStrings> => {
      switch (languageCode) {
        case 'it':
          return (await import('./it/localization.json')).default;
        default:
          return (await import('./en/localization.json')).default; // Default to English
      }
    };

    // Load the corresponding language file
    loadLanguage(langCode).then((strings) => {
      setLanguageStrings(strings);
    });

  }, [langCode]);

  const normalizeKey = (variable: string): string => {
    // Convert the string to lowercase
    return variable
      .toLowerCase()                          // Convert all characters to lowercase
      .replace(/[^a-z0-9\s._]/g, '')           // Remove any character that is not a letter, number, space, dot or underscore
      .replace(/[.\s]+/g, '_');               // Replace spaces and dots with underscores
  };

  const getNestedValue = (obj: any, path: string): any => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };


  const t_media = (source: string): string => {

    if (langCode === 'en') {
      return source
    } else {
      return source.replace(/pmotiontech\.com\/media/g, 'pmotiontech.com/it/media');
    }


  }

  const t = (token: string, variable?: string): string => {
    //const tokenPath = variable ? `${token}.${variable}` : token;
    //return languageStrings[tokenPath] || variable ? variable: token; // Return translation or original token if not found
    
    if (variable) {
      var v_key = normalizeKey(variable)
      const tokenPath = token + "." + v_key
      console.log(v_key)
      return `${getNestedValue(languageStrings, tokenPath.toLowerCase()) || variable}`;
      
    
    }
    //return  `${get(languageStrings, token.toLowerCase(), token.toLowerCase())}`;

    return  `${get(languageStrings, token.toLowerCase(), token)}`;

  };

  return { country, measurementUnit, hasLanguageLoaded, t, t_media };
};
