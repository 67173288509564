import {createStore, applyMiddleware, Middleware, StoreEnhancer} from 'redux';
import {persistStore} from 'redux-persist';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootSaga from './root.sagas';

import rootReducer from './root.reducer';

const sagaMiddleware = createSagaMiddleware();

let storeEnhancer:StoreEnhancer;
const middlewares:Middleware[] = [sagaMiddleware];
if( process.env.NODE_ENV === 'development' ){
    middlewares.push( logger );
    
    storeEnhancer = composeWithDevTools(
        applyMiddleware(...middlewares)
    );
} else {
    storeEnhancer = applyMiddleware(...middlewares);
}

export const store = createStore(rootReducer, storeEnhancer);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

export default {store, persistor};