import {
    AppBar,
    Box,
    ClickAwayListener,
    Grow,
    Hidden,
    IconButton,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Toolbar,
    Typography,
} from '@material-ui/core';
import { Badge, Button } from '@material-ui/core';
import {ConnectedProps, connect} from 'react-redux';
import { ITokenPayload, IUser } from '../../redux/user/user.types';
import React, {Fragment, HTMLAttributes, memo, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import { Link as RouterLink, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { darken, lighten, makeStyles, rgbToHex } from '@material-ui/core/styles';
import { getAccountPath, getMessagesPath } from '../../utils/routes';
import { selectAllOrgs, selectOrgInfo, selectOrgLastLoginUrl, selectTopOrgInfo } from '../../redux/org/org.selectors';

import Avatar from '../avatar/avatar.component';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import {IOrg} from '../../redux/org/org.types';
import {IRootState} from '../../redux/root.types';
import MenuIcon from '@material-ui/icons/Menu';
import MessageIcon from '@material-ui/icons/Message';
import NavDrawer from '../nav-drawer/nav-drawer.component';
import OrgPicker from '../org-picker/org-picker.component';
import ResponsiveImage from '../responsive-image/responsonsive-image.component';
import Routes from '../../utils/routes';
import { Sizes } from '../../utils/style';
import clsx from "clsx";
import {createStructuredSelector} from 'reselect';
import { debounce } from 'lodash';
import { getNewMessageThreadCount } from '../../redux/message/message.actions';
import { headerRef } from '../../utils/PMotionGlobals';
import {selectCurrentUser} from '../../redux/user/user.selectors';
import { selectNewMessageThreadCount } from '../../redux/message/message.selectors';
import {signOutStart} from '../../redux/user/user.actions'
import { useLocalization } from '../../localization/useLocalization';

const useStyles = makeStyles(theme => ({
    menuButton: {
        marginRight: theme.typography.pxToRem(10),
        //marginLeft: theme.typography.pxToRem(-12),
        paddingLeft: theme.typography.pxToRem(15),
        paddingRight: theme.typography.pxToRem(4),

        '& .MuiSvgIcon-root': {
            fontSize: theme.typography.pxToRem(40)
        },

        [theme.breakpoints.up('lg')]: {
            paddingLeft: 0
        },
    },
    grow: {
        flexGrow: 1
    },
    flex: {
        display: 'flex',
        alignItems: 'center'
    },
    offset: {
        ...theme.mixins.toolbar
    },
    toolbar: {
        width: "100%",
        maxWidth: Sizes.MaxWidth,
        marginLeft: "auto",
        marginRight: "auto",
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        flexGrow: 1,

        [theme.breakpoints.down("lg")]: {
            width: `calc(100vw - ${Sizes.MobileGutter * 2}px)`,
        },
    },
    toolbarHeight: {
        minHeight: Sizes.HeaderHeight,
        maxHeight: Sizes.HeaderHeight,
        paddingLeft: 0,
        //paddingRight: theme.typography.pxToRem(13),
        paddingRight: 0
    },
    splitLeft: {
        flex: '1 0 auto',
        alignItems: 'center',
        
        [theme.breakpoints.down("xs")]: {
            marginLeft: -6
        },
    },
    splitRight: {
        flex: '0 0 auto',
        alignItems: 'center',
        justifyContent: 'flex-end',

        [theme.breakpoints.down("xs")]: {
            marginRight: -10
        },
    },
    avatar: {
        width: theme.typography.pxToRem(26),
        height: theme.typography.pxToRem(26),

        '&.fallback': {
            width: theme.typography.pxToRem(24),
            height: theme.typography.pxToRem(24),
            color: `${theme.palette.primary.main}`,
            backgroundColor: rgbToHex(lighten(theme.palette.primary.main, .4)),

            [theme.breakpoints.down('sm')]: {
                width: 20,
                height: 20,
            },
        }
    },
    badge: {
        '& .MuiBadge-badge': {
            backgroundColor: theme.palette.info.main,
            color: theme.palette.info.contrastText
        }
    },
    orgLogo: {
        height: theme.typography.pxToRem(40),
        marginLeft: theme.typography.pxToRem(5),
        marginRight: theme.typography.pxToRem(14),
        overflow: 'visible',
    },
    orgInfo: {
        display: "flex",
        alignItems: 'center',
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        height: Sizes.HeaderHeight,
        paddingLeft: 16,
        paddingRight: 8
    },
    orgInfoContainer: {
        display: 'inline-flex',
        paddingRight: 50,

        [theme.breakpoints.down('sm')]: {
            paddingRight: 16,
        },
    },
    orgInfoButton: {
        textAlign: 'left',
        justifyContent: 'flex-start',
        minWidth: theme.typography.pxToRem(303),
        borderRadius: 0,

        [theme.breakpoints.down('sm')]: {
            minWidth: 'auto'
        },

        '& .MuiButton-endIcon': {
            marginLeft: 14,
            marginRight: 0
        },

        '& .MuiButton-iconSizeMedium > *:first-child': {
            fontSize: theme.typography.pxToRem(21),
        },

        '& .orgName': {
            flexGrow: 1
        },

        '&:hover': {
            backgroundColor: darken(theme.palette.secondary.main, .1),
            color: theme.palette.secondary.contrastText,
        }
    },
    orgHeader: {
        color: 'inherit',
    },
    orgTitle: {
        lineHeight: theme.typography.pxToRem(22)
    },
    orgOccupation: {
        lineHeight: theme.typography.pxToRem(18)
    },
    flexStack: {
        display: 'flex',
        flexDirection: 'column'
    },
    headerIconButton: {
        padding: 0,
        margin: 0,
        //paddingRight: theme.typography.pxToRem(15),
        paddingLeft: theme.typography.pxToRem(4),
    },
    headerIcon: {
        fontSize: 30,
        color: rgbToHex(lighten(theme.palette.primary.main, .4)),

        [theme.breakpoints.down('sm')]: {
            fontSize: 24,
        },
    },
    userMenuTrigger: {
        marginLeft: 22,

        [theme.breakpoints.down('sm')]: {
            marginLeft: 15,
        },
    },
    logo: {
        flexGrow: 0,
        flexShrink: 1,
        paddingLeft: theme.typography.pxToRem(18),

        '& img': {
            height: theme.typography.pxToRem(28)
        }
    },
    messagesIconButton: {
        marginTop: theme.typography.pxToRem(2),
    },
    adminHeader: {
        color: theme.palette.primary.contrastText,
        fontSize: theme.typography.pxToRem(18)
    }
}));

type OrgContainerProps = Pick<HTMLAttributes<HTMLDivElement>, 'children' | 'className'> & {
    subOrg?:IOrg;
    onClick?:() => void;
    isButton:boolean;
}
export const OrgContainer:React.FC<OrgContainerProps> = memo(({subOrg, children, isButton, ...otherProps}) => {
    return isButton ? (
        <Button {...otherProps} endIcon={<FilterNoneIcon />}>{children}</Button>
    ) : subOrg ? (
        <div {...otherProps}>{children}</div>
    ) : null;
});
OrgContainer.displayName = 'OrgContainer';

interface mapStateToPropsInterface  {
    org?:IOrg;
    allOrgs?:IOrg[];
    topOrg?:IOrg;
    user:IUser | undefined | null;
    lastLoginUrl?:string;
    newMessageCount?:number;
}
const mapStateToProps = createStructuredSelector<IRootState, mapStateToPropsInterface>({
    org: selectOrgInfo,
    allOrgs: selectAllOrgs,
    topOrg: selectTopOrgInfo,
    user: selectCurrentUser,
    lastLoginUrl: selectOrgLastLoginUrl,
    newMessageCount: selectNewMessageThreadCount
});

const mapDispatchToProps = {
    signOutStart,
    getNewMessageThreadCount
};

const connector = connect(
    mapStateToProps,
    mapDispatchToProps
);

type PropsFromRedux = ConnectedProps<typeof connector>;

type HeaderProps = PropsFromRedux & {
    userMenuOpen?:boolean;
};

export const Header:React.FC<HeaderProps> = ({org, topOrg, allOrgs, user, newMessageCount, signOutStart, lastLoginUrl, getNewMessageThreadCount, userMenuOpen=false}) => {
    const classes = useStyles();

    
    const {t, hasLanguageLoaded} = useLocalization();

    const history = useHistory();
    const location = useLocation();

    const adminMatch = useRouteMatch(Routes.ADMIN);
    const reportingMatch = useRouteMatch(Routes.REPORTS);

    // Nav Drawer
    const [drawerOpen, setDrawerOpen] = useState(false);
    
    // Account Menu
    const [menuOpen, setMenuOpen] = useState(userMenuOpen);
    const menuTriggerRef = useRef(null);

    // Org Picker
    const [orgPickerOpen, setOrgPickerOpen] = useState<boolean>(false);

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(menuOpen);
    useEffect(() => {
        if (prevOpen.current === true && menuOpen === false && menuTriggerRef && menuTriggerRef.current) {
            (menuTriggerRef.current! as HTMLButtonElement).focus();
        }
        prevOpen.current = menuOpen;
    }, [menuOpen]);

    const handleMenuToggle = () => {
        setMenuOpen(prevOpen => !prevOpen);
    };

    const handleMenuClose = (event:React.MouseEvent<Document>) => {
        if (menuTriggerRef.current && (menuTriggerRef.current! as HTMLButtonElement).contains(event.target as Node)) {
            return;
        }

        setMenuOpen(false);
    };

    const handleMenuListKeyDown = (event:React.KeyboardEvent) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setMenuOpen(false);
        }
    };

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const handleSignOutClick = (event:React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        const signoutPayload:ITokenPayload | undefined = user ? {
            token: user.token
        } : undefined;
        signOutStart(signoutPayload);
        if(lastLoginUrl){
            history.push(lastLoginUrl);
        }
        setMenuOpen(false);
    };

    const handleUserClick = (event:React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        history.push( getAccountPath() );
        setMenuOpen(false);
    };

    const handleOrgPickerOpen = useCallback(() => {
        setOrgPickerOpen(true);
    }, [setOrgPickerOpen]);

    const handleOrgPickerClose = useCallback(() => {
        setOrgPickerOpen(false);
    }, [setOrgPickerOpen]);

    const isOrgButton = useMemo(() => {
        return !!(org && topOrg && topOrg.children && topOrg.children.length);
    }, [org, topOrg]);

    const showNewMessageCount = useMemo(() => newMessageCount ? newMessageCount : undefined, [newMessageCount]);

    const getNewMessageCountDelay = useRef<number>(2500);
    const getNewMessageCount = useCallback(debounce(() => {
        if(user){
            getNewMessageThreadCount({
                userId: user.id,
                token: user.token,
                failSilently: true
            });
		}
	}, getNewMessageCountDelay.current, {
		leading: false,
		trailing: true
	}), [getNewMessageThreadCount, user, getNewMessageCountDelay]);

    
    useEffect(() => {
        getNewMessageCount();
        // make delay longer for further page navigation
        getNewMessageCountDelay.current = 6500;
	}, [location, getNewMessageCount, getNewMessageCountDelay]);

    return (
        <Fragment>
            <AppBar position="fixed" elevation={0}>
                <Toolbar className={clsx(classes.toolbar, classes.toolbarHeight)}>
                    <Box display="flex" className={classes.splitLeft}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="Open Nav"
                            onClick={handleDrawerOpen}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                        <div className={classes.grow}>
                            {adminMatch ? (
                                <Typography component="h1" variant="h1" className={classes.adminHeader}>{t("common.drawer.admin")}</Typography>
                            ) : reportingMatch ? (
                                <Typography component="h1" variant="h1" className={classes.adminHeader}>{t("page.reporting.reporting_label")}</Typography>
                            ) : (
                                <Fragment>
                                    <OrgContainer subOrg={org} className={clsx(classes.orgInfo, isOrgButton ? classes.orgInfoButton : classes.orgInfoContainer)} isButton={isOrgButton} onClick={isOrgButton ? handleOrgPickerOpen : undefined}>
                                        {org && org.image && (
                                            <Hidden xsDown>
                                                <ResponsiveImage srcMapping={org.image} alt={org.displayName} className={classes.orgLogo} sizeToHeight />
                                            </Hidden>
                                        )}
                                        {topOrg && org && (
                                            <div className={clsx('orgName', classes.flexStack)}>
                                                <Typography component="span" variant="h2" className={`${classes.orgHeader} ${classes.orgTitle}`}>
                                                    {topOrg.displayName}
                                                </Typography>
                                                <Typography component="span" className={`${classes.orgHeader} ${classes.orgOccupation}`}>
                                                    {org.displayName}
                                                </Typography>
                                            </div>
                                        )}
                                    </OrgContainer>
                                    <OrgPicker open={orgPickerOpen} onClose={handleOrgPickerClose} />
                                </Fragment>
                            )}
                        </div>
                    </Box>
                    <Box display="flex" className={classes.splitRight}>
                        <IconButton
                            className={clsx(classes.headerIconButton, classes.messagesIconButton)}
                            component={RouterLink} 
                            to={getMessagesPath()}
                            edge="end"
                            aria-label="Messages"
                            color="inherit"
                        >
                            {showNewMessageCount && showNewMessageCount > 0 ? (
                                <Badge badgeContent={showNewMessageCount} className={classes.badge}>
                                    <MessageIcon className={classes.headerIcon} />
                                </Badge>
                            ) : (
                                <MessageIcon className={classes.headerIcon} />
                            )}
                        </IconButton>
                        {user && (
                            <Fragment>
                                <IconButton
                                    className={clsx(classes.headerIconButton, classes.userMenuTrigger)}
                                    ref={menuTriggerRef} 
                                    onClick={handleMenuToggle} 
                                    edge="end"
                                    aria-label="Account Menu"
                                    //aria-controls={menuId}
                                    aria-haspopup="true"
                                    //onClick={handleProfileMenuOpen}
                                    color="inherit"
                                >
                                    <Avatar variant="rounded" alt={user.displayName} src={user.image} className={classes.avatar} />
                                </IconButton>
                                <Popper open={menuOpen} anchorEl={menuTriggerRef.current || document?.body} role={undefined} transition disablePortal>
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                            >
                                            <Paper>
                                                <ClickAwayListener onClickAway={handleMenuClose}>
                                                <MenuList autoFocusItem={menuOpen} onKeyDown={handleMenuListKeyDown}>
                                                    <MenuItem onClick={handleUserClick}>{t('common.label.account_info')}</MenuItem>
                                                    <MenuItem className="sign-out" onClick={handleSignOutClick}>{t('common.label.sign_out')}</MenuItem>
                                                </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </Fragment>
                        )}
                    </Box>
                </Toolbar>
            </AppBar>
            <div className={clsx(classes.offset, classes.toolbarHeight)} ref={headerRef} />
            <NavDrawer isOpen={drawerOpen} onDrawerClose={handleDrawerClose} />
        </Fragment>
    )
};

export default connector(Header);